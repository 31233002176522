import { Component, OnInit,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var $: any;


@Component({
  selector: 'app-claim-rewards',
  templateUrl: './claim-rewards.component.html',
  styleUrls: ['./claim-rewards.component.css']
})
export class ClaimRewardsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.document.body.classList.add('special-header');
    $('.navbar.navbar-sticky').addClass('navbar-sticky-moved-up navbar-sticky-transitioned navbar-sticky-on')
    $(window).scroll(function(){
    $('.navbar.navbar-sticky').addClass('navbar-sticky-moved-up navbar-sticky-transitioned navbar-sticky-on')
  });
  }

}
