import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule ,ToastContainerModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { VideoContestComponent } from './components/video-contest/video-contest.component';
//import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ClaimRewardsComponent } from './pages/claim-rewards/claim-rewards.component';
import { HeaderComponent } from './pages/header/header.component';
import { FooterComponent } from './pages/footer/footer.component';
import { StarMarketComponent } from './pages/star-market/star-market.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { HomeComponent } from './pages/home/home.component';
import { FooterNewComponent } from './pages/footer-new/footer-new.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TermsConditionsComponent } from './pages/policies/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/policies/privacy-policy/privacy-policy.component';
import { LawEnforcementsComponent } from './pages/policies/law-enforcements/law-enforcements.component';
import { IntellectualPropertyPolicyComponent } from './pages/policies/intellectual-property-policy/intellectual-property-policy.component';
import { AdvertisingPoliciesComponent } from './pages/policies/advertising-policies/advertising-policies.component';
import { AdvertiseOgComponent } from './pages/advertise-og/advertise-og.component';
import { BecomeOgCreatorComponent } from './pages/become-og-creator/become-og-creator.component';
import { CommunityGuidelinesComponent } from './pages/policies/community-guidelines/community-guidelines.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    VideoContestComponent,
    PrivacyPolicyComponent,
    ClaimRewardsComponent,
    HeaderComponent,
    FooterComponent,
    StarMarketComponent,
    HomeComponent,
    FooterNewComponent,
    ContactUsComponent,
    TermsConditionsComponent,
    LawEnforcementsComponent,
    IntellectualPropertyPolicyComponent,
    AdvertisingPoliciesComponent,
    AdvertiseOgComponent,
    BecomeOgCreatorComponent,
    CommunityGuidelinesComponent,

  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    //AngularFileUploaderModule,
    HttpClientModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot({closeButton:true, timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,}),
      NgImageSliderModule
  ],
  providers: [ToastrModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
