import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient , HttpParams,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InfluencerService {

  reqHeader:any;   
  constructor(private httpClient: HttpClient) { 
   
      this.reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('ubu_auth_token')
      });
    }
    
    SubmitInfluence(updateData:any) : Observable<any>{
      let apiUrlContestVideo = 'https://api.ohgaggle.com/api/v1/contactusInfluencer';
     return this.httpClient.post(apiUrlContestVideo, updateData, { headers: this.reqHeader });
   }

  }
