import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.css']
})
export class FooterNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 120) {
        $('.toTopBtn').fadeIn();
      } else {
        $('.toTopBtn').fadeOut();
      }
    });
  
    $('.toTopBtn').click(function() {
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });
  }

}
