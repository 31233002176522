import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-law-enforcements',
  templateUrl: './law-enforcements.component.html',
  styleUrls: ['./law-enforcements.component.css']
})
export class LawEnforcementsComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
    this.document.body.classList.add('header_m1');
  }

}
