import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
    this.document.body.classList.add('header_m1');
  }

}
