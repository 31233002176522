import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-community-guidelines',
  templateUrl: './community-guidelines.component.html',
  styleUrls: ['./community-guidelines.component.css']
})
export class CommunityGuidelinesComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
    this.document.body.classList.add('header_m1');
  }

}
