import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-advertise-og',
  templateUrl: './advertise-og.component.html',
  styleUrls: ['./advertise-og.component.css']
})
export class AdvertiseOgComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
    this.document.body.classList.add('header_m1');
  }

}
