import { Component, OnInit , ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VideoContestService } from '../../services/video-contest/video-contest.service';
import {ContactUsService} from '../../services/contact-us/contact-us.service';
import * as AOS from 'aos';
declare var $: any;

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
    ogVCForm: FormGroup;
    ogContactForm: FormGroup;
    ogSubscribeForm: FormGroup;
    isOGVCFormSubmitted: boolean = false;
    isOGContactFormSubmitted: boolean = false;
    isOGSubscribeFormSubmitted: boolean = false;
    invalidFileType: boolean = false;
    invalidFileSize: boolean = false;
    fileuploadError: any;
    base64videoFile: any;
    @ViewChild('videoFile', { static: true }) ohContestvideoFile: ElementRef;
    
    constructor(private formBuilder: FormBuilder, private videoContestService: VideoContestService, private ngxUiLoaderService:NgxUiLoaderService, private toastrService :ToastrService) { }

    ngOnInit() {
        AOS.init();
      this.ogVCForm = this.formBuilder.group({
            og_full_name: ['', [Validators.required, Validators.minLength(3)]],
            og_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
            og_email: ['', [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
            og_video_file: ['', Validators.required],
            og_tos: ['', [Validators.required]]

        });

        this.ogContactForm = this.formBuilder.group({
            ogc_full_name: ['', [Validators.required, Validators.minLength(3)]],
            ogc_email: ['', [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
            ogc_subject: ['', [Validators.required, Validators.minLength(6)]],
            ogc_message: ['', [Validators.required, Validators.minLength(20)]]
        });

        // INPUT ANIMATIONS CODE
        
        // INPUT ANIMATIONS CODE END

    }

    get ogvcFormControl() { return this.ogVCForm.controls; }
    get ogContactFormControl() { return this.ogContactForm.controls; }

    contestVideoUpload(postData: any) {
        this.ngxUiLoaderService.start();
        this.isOGVCFormSubmitted = true;
        if (this.ogVCForm.invalid) {
            this.toastrService.info('', "Please provide all the mandatory information");
            this.ngxUiLoaderService.stop();
            return false;
        }      
        if (postData) {
            const requestData = {
                "full_name": postData["og_full_name"],
                "email": postData["og_email"],
                "phone": postData["og_phone"],
                "video_path": [this.base64videoFile]
            }
            this.videoContestService.uploadContestForm(requestData).subscribe(
                response => {
                    if (response['status_code'] == 200) {
                        this.ngxUiLoaderService.stop();
                        this.toastrService.success('', 'Contest video uploaded successfully'); 
                        this.isOGVCFormSubmitted= false;
                        this.ogVCForm.reset();
                                               
                    }

                    if (response['status_code'] == 201) {
                         this.ngxUiLoaderService.stop();
                         this.toastrService.info('', "Some thing went wrong");
                    }
                }, error => {
                     this.ngxUiLoaderService.stop();
                    this.toastrService.info('', "Some thing went wrong");
                });

        }
    }

    contactUs(postData: any) {
        this.ngxUiLoaderService.start();
        this.isOGContactFormSubmitted = true;
        if (this.ogVCForm.invalid) {
            this.toastrService.info('', "Please provide all the mandatory information");
            this.ngxUiLoaderService.stop();
            return false;
        }
        if (postData) {

        }   
        this.ngxUiLoaderService.stop();
    }

    onFileChange(event: any) {
        const extenstionList = ['video/wmv', 'video/mp4', 'video/3GP', 'video/mov', 'video/avi'];
        const max_size = 52428800;

        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;

            if (!extenstionList.includes(event.target.files[0].type.toLowerCase())) {
                console.log(extenstionList.includes(event.target.files[0].type.toLowerCase()));
                this.fileuploadError = 'Only videos files are allowed ( WMV | MP4 | MOV | AVI | 3GP )';
                this.toastrService.info('', this.fileuploadError);
                this.ohContestvideoFile.nativeElement.value="";
                this.invalidFileType = true;
                return false;
            }


            if (event.target.files[0].size > max_size) {
                this.fileuploadError = 'Maximum size allowed is ' + max_size / 1024 + 'Mb';
                this.toastrService.info('', this.fileuploadError);
                this.ohContestvideoFile.nativeElement.value="";
                this.invalidFileSize = false;
                return false;
            }

            var ohfile: File = event.target.files[0];
            var ohFileReader: FileReader = new FileReader();

            ohFileReader.onloadend = (e) => {
                this.base64videoFile = ohFileReader.result;
            }
            ohFileReader.readAsDataURL(ohfile);


        }

    }

    comingSoon(){
        this.toastrService.success('', "Coming Soon!");
    }

    privacyPolicy(){
       $("#termsofService").modal('show');
    }

    contactus(){

    }

    subscribeUs(){

    }

    /* validateFile(name:string){
       var extenstionList= ['wmv','mp4','3GP','mov','avi'];  
       var ext = name.substring(name.lastIndexOf('.') + 1);
       if (extenstionList.includes(ext.toLowerCase())) {        
           return true;
       }
       else {      
           return false;
       }
   
       
     }
   
     readThis(inputValue: any): void {
       var file:File = inputValue.files[0];
       var myReader:FileReader = new FileReader();
     
       myReader.onloadend = (e) => {
         this.base64videoFile = myReader.result;      
       }
       myReader.readAsDataURL(file);
     }*/

}
