import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgImageSliderComponent } from 'ng-image-slider';



declare var $: any;
declare var Swiper: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('nav',{static: false}) slider: NgImageSliderComponent;
  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
  
    this.document.body.classList.add('header_m1');
    var swiper = new Swiper('#slider_m3', {
      slidesPerView: 1,
      spaceBetween: 30,
     
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 4000,
       // disableOnInteraction: true,
        
    },
   
    breakpoints: {
      640: {
        slidesPerView:1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 50,
        slidesPerGroup:2,
      },
    }
     
    });

    // var showChar = 50;
    // var ellipsestext = "...";
    // var moretext = "more";
    // var lesstext = "less";
    // $('.more').each(function() {
     
    //   var content = $(this).html();
  
    //   if(content.length > showChar) {
  
    //     var c = content.substr(0, showChar);
    //     var h = content.substr(showChar-1, content.length - showChar);
  
    //     var html = c + '<span class="moreellipses">' + ellipsestext+ '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<a href="" class="morelink">' + moretext + '</a></span>';
  
    //     $(this).html(html);
    //   }
  
    // });
  
    // $(".morelink").click(function(){
    //   if($(this).hasClass("less")) {
    //     $(this).removeClass("less");
    //     $(this).html(moretext);
    //   } else {
    //     $(this).addClass("less");
    //     $(this).html(lesstext);
    //   }
    //   $(this).parent().prev().toggle();
    //   $(this).prev().toggle();
    //   return false;
    // });


    var swiper = new Swiper('#slider_m5', {
      slidesPerView: 1,
      spaceBetween: 30,
     
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 4000,
       // disableOnInteraction: true,
        
    },
   
    breakpoints: {
      640: {
        slidesPerView:1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 50,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
        slidesPerGroup:2,
      },
    }
     
    });


    var swiper = new Swiper('#slider_m7', {
      slidesPerView: 1,
      spaceBetween: 30,
     
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 4000,
       // disableOnInteraction: true,
        
    },
   
    // breakpoints: {
    //   640: {
    //     slidesPerView:1,
    //     spaceBetween: 20,
    //   },
    //   768: {
    //     slidesPerView: 2,
    //     spaceBetween: 40,
    //   },
    //   991: {
    //     slidesPerView: 3,
    //     spaceBetween: 50,
    //   },
 
    // }
     
    });
    

    

  }

  // slider
  
  imageObject: Array<object> = [{
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    video: 'https://youtu.be/6pxRHBw-k8M', // Youtube url
    title: 'Lorem Ipsum',
},

{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
  title: 'Lorem Ipsum',
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
  title: 'Lorem Ipsum',
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
  title: 'Lorem Ipsum',
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
  title: 'Lorem Ipsum',
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
  title: 'Lorem Ipsum',
},
{
  video: 'assets/video/movie2.mp4',
    posterImage: 'https://youtu.be/nRAVuDbXo7M', //Optional: You can use this key if you want to show video poster image in slider
    title: 'Lorem Ipsum',
    
},


];


prevImageClick() {
 this.slider.prev();
 // $('.slider_foot ul').css('margin-left','-=10px');

 $('.slider_foot ul,.slider_4 .ng-image-slider').animate({
  scrollLeft: "-=285px"
}, "slow");
event.preventDefault();
}

nextImageClick() {
   this.slider.next();
  
  
  $('.slider_foot ul,.slider_4 .ng-image-slider').animate({
    scrollLeft: "+=285px"
  }, "slow");
  event.preventDefault();

}

}
