import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-become-og-creator',
  templateUrl: './become-og-creator.component.html',
  styleUrls: ['./become-og-creator.component.css']
})
export class BecomeOgCreatorComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document ) { }

  ngOnInit() {
    this.document.body.classList.add('header_m1');
  }

}
