import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { VideoContestComponent } from './components/video-contest/video-contest.component';
import { ClaimRewardsComponent } from './pages/claim-rewards/claim-rewards.component';
import { StarMarketComponent } from './pages/star-market/star-market.component';
import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
//import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

import { PrivacyPolicyComponent } from './pages/policies/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/policies/terms-conditions/terms-conditions.component';
import { LawEnforcementsComponent } from './pages/policies/law-enforcements/law-enforcements.component';
import { CommunityGuidelinesComponent } from './pages/policies/community-guidelines/community-guidelines.component';
import { IntellectualPropertyPolicyComponent } from './pages/policies/intellectual-property-policy/intellectual-property-policy.component';
import { AdvertisingPoliciesComponent } from './pages/policies/advertising-policies/advertising-policies.component';
import { AdvertiseOgComponent } from './pages/advertise-og/advertise-og.component';
import { BecomeOgCreatorComponent } from './pages/become-og-creator/become-og-creator.component';
const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'video-contest', component: VideoContestComponent},
    {path: 'claim-rewards', component: ClaimRewardsComponent},
    {path: 'gaggle-influencer', component: StarMarketComponent},
   // {path: 'home', component: HomeComponent},
    {path: 'contact', component: ContactUsComponent},
   
   
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-and-conditions', component: TermsConditionsComponent},
    {path: 'law-enforcements', component: LawEnforcementsComponent},
    {path: 'intellectual-policy', component: IntellectualPropertyPolicyComponent},
    {path: 'advertising-policy', component: AdvertisingPoliciesComponent},
    {path: 'community-policy', component: CommunityGuidelinesComponent},
    
    {path: 'advertise-og', component: AdvertiseOgComponent},
    {path: 'become-creator', component: BecomeOgCreatorComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
