import { Component, OnInit,Inject , ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { InfluencerService } from '../../services/GaggleInfluencer/influencer.service';
declare var $: any;


import { DOCUMENT } from '@angular/common';
import { NgImageSliderComponent } from 'ng-image-slider';

declare var Swiper: any;
declare var videojs: any;
declare var jQuery: any;
window['$'] = jQuery;
@Component({
  selector: 'app-star-market',
  templateUrl: './star-market.component.html',
  styleUrls: ['./star-market.component.css']
})
export class StarMarketComponent implements OnInit {
  ogInluencerForm: FormGroup;
  isInluenceFormSubmitted: boolean = false;
  
  @ViewChild('nav',{static: false}) slider: NgImageSliderComponent;
  
  constructor(@Inject(DOCUMENT) private document: Document ,private formBuilder: FormBuilder, 
  private InfluencerService: InfluencerService, private ngxUiLoaderService: NgxUiLoaderService, private toastrService: ToastrService) { }

  ngOnInit() {

    this.ogInluencerForm = this.formBuilder.group({
      og_name: ['', [Validators.required, Validators.minLength(3)]],
      og_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      og_email: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      og_gender:['',[Validators.required]],
      og_tos: ['', [Validators.requiredTrue]]

  });

    this.document.body.classList.add('header_m1');
  

    var swiper = new Swiper('#slider_m1', {
      slidesPerView: 2,
      spaceBetween: 30,
      loop: true,
      freeMode: true,
      mode:'horizontal',
   
momentumRatio: 1,

freeModeFluid: true,
    grabCursor: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 2000,
       // disableOnInteraction: true,
        
    },
    breakpoints: {
      640: {
        slidesPerView:2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      991: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
    }
     
    });


    var swiper = new Swiper('#slider_m2', {
      slidesPerView: 1,
      spaceBetween: 30,
  
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 5000,
       // disableOnInteraction: true,
        
    },
  
     
    });


    var swiper = new Swiper('#slider_m3', {
      slidesPerView: 1,
      spaceBetween: 30,
     
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 4000,
       // disableOnInteraction: true,
        
    },
   
    breakpoints: {
      640: {
        slidesPerView:1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
        slidesPerGroup:4,
      },
    }
     
    });
  
    $("#upClick").click(function (){
      $('html, body').animate({
          scrollTop: $(".form_set").offset().top
      }, 2000);
  });
   

  


  }

  ngAfterViewInit(){

}
 

  imageObject: Array<object> = [{
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    video: 'https://youtu.be/6pxRHBw-k8M' // Youtube url
},

{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
},
{
  video: 'https://youtu.be/nRAVuDbXo7M', // MP4 Video url
},
{
  video: 'assets/video/movie2.mp4',
    posterImage: 'https://youtu.be/nRAVuDbXo7M', //Optional: You can use this key if you want to show video poster image in slider
    title: 'Image title',
     h1:'ravi'
},


];


prevImageClick() {
 this.slider.prev();
 // $('.slider_foot ul').css('margin-left','-=10px');

 $('.slider_foot ul,.slider_4 .ng-image-slider').animate({
  scrollLeft: "-=285px"
}, "slow");
event.preventDefault();
}

nextImageClick() {
   this.slider.next();
  
  
  $('.slider_foot ul,.slider_4 .ng-image-slider').animate({
    scrollLeft: "+=285px"
  }, "slow");
  event.preventDefault();

}
get ogInfFormControl() { return this.ogInluencerForm.controls; }
influencerFormSubmit(postData: any) {
  this.isInluenceFormSubmitted = true;
  if (this.ogInluencerForm.invalid) {
      //this.toastrService.info('', "Please provide all the mandatory information");
      this.ngxUiLoaderService.stop();
      return false;
  }
  if (postData) {
  this.ngxUiLoaderService.start();
      const requestData = {
          "name": postData["og_name"],
          "email": postData["og_email"],
          "phone": postData["og_phone"],
          "gender": postData["og_gender"]
      }
      this.InfluencerService.SubmitInfluence(requestData).subscribe(
          response => {
            console.log('test',response);
              if (response['status_code'] == 200) {
                  this.ngxUiLoaderService.stop();
                  this.toastrService.success('', response['success'].message);
                  this.isInluenceFormSubmitted = false;
                  this.ogInluencerForm.reset();
              }

              if (response['status_code'] == 201) {
                  this.ngxUiLoaderService.stop();
                  this.toastrService.info('', "Some thing went wrong");
              }
          }, error => {
              this.ngxUiLoaderService.stop();
              this.toastrService.info('', "Some thing went wrong");
          });

  }
}

}
