import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VideoContestService } from '../../services/video-contest/video-contest.service';
import { ContactUsService } from '../../services/contact-us/contact-us.service';
import * as AOS from 'aos';
declare var $: any;

@Component({
    selector: 'app-video-contest',
    templateUrl: './video-contest.component.html',
    styleUrls: ['./video-contest.component.css']
})
export class VideoContestComponent implements OnInit {
    ogVCForm: FormGroup;
    ogSubscribeForm: FormGroup;
    isOGVCFormSubmitted: boolean = false;
    isValidFileType: boolean = false;
    isValidFileSize: boolean = false;
    fileuploadError: any;
    base64videoFile: any;
    isValidName: boolean = false;
    isValidPhone: boolean = false;
    isValidEmail: boolean = false;
    isFileEncoded: boolean = false;
    isTosValid: boolean = false;
    isPhoneChanged: boolean = false;
    isEmailChanged: boolean = false;
    isNameChanged: boolean = false;
    isFileChanged: boolean = false;
    isTosChanged: boolean = false;
    isSubmitBntEnabled: boolean = false;

    iftermsofServiceAccepted: boolean = false;


    @ViewChild('videoFile', { static: true }) ohContestvideoFile: ElementRef;

    constructor(private formBuilder: FormBuilder, private videoContestService: VideoContestService, private ngxUiLoaderService: NgxUiLoaderService, private toastrService: ToastrService) { }

    ngOnInit() {
        AOS.init();
        this.ogVCForm = this.formBuilder.group({
            og_full_name: ['', [Validators.required, Validators.minLength(3)]],
            og_phone: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
            og_email: ['', [
                Validators.required,
                Validators.email,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
            og_video_file: ['', Validators.required],
            og_tos: ['', [Validators.required]]

        });

        $('document').ready(function() {
            $('.privacyPolicyBtn').click(function() {
            
              $('#termsofService').modal('show');
            });
          });

    }
    get ogvcFormControl() { return this.ogVCForm.controls; }
    contestVideoUpload(postData: any) {
        this.ngxUiLoaderService.start();
        this.isOGVCFormSubmitted = true;
        if (this.ogVCForm.invalid) {
            this.toastrService.info('', "Please provide all the mandatory information");
            this.ngxUiLoaderService.stop();
            return false;
        }
        if (postData) {
            const requestData = {
                "full_name": postData["og_full_name"],
                "email": postData["og_email"],
                "phone": postData["og_phone"],
                "video_path": [this.base64videoFile]
            }
            this.videoContestService.uploadContestForm(requestData).subscribe(
                response => {
                    if (response['status_code'] == 200) {
                        this.ngxUiLoaderService.stop();
                        this.toastrService.success('', 'Thank you, contest video uploaded successfully');
                        this.isOGVCFormSubmitted = false;
                        this.resetFormData();
                        this.ogVCForm.reset();

                    }

                    if (response['status_code'] == 201) {
                        this.ngxUiLoaderService.stop();
                        this.toastrService.info('', "Some thing went wrong");
                    }
                }, error => {
                    this.ngxUiLoaderService.stop();
                    this.toastrService.info('', "Some thing went wrong");
                });

        }
    }

    onFileChange(event: any) {
        this.ngxUiLoaderService.start();
        const extenstionList = ['video/wmv', 'video/mp4', 'video/3GP', 'video/m4v', 'video/mov', 'video/x-m4v', 'video/avi', 'video/*', 'video/x-flv', 'video/quicktime', 'video/3gpp', 'video/MP2T', 'video/x-ms-wmv', 'video/x-msvideo'];
        const max_size = 10485760;
        this.isFileEncoded = false;
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;

            if (!extenstionList.includes(event.target.files[0].type.toLowerCase())) {
                console.log(extenstionList.includes(event.target.files[0].type.toLowerCase()));
                this.fileuploadError = 'Only videos files are allowed';
                this.toastrService.info('', this.fileuploadError);
                this.ohContestvideoFile.nativeElement.value = "";
                this.isValidFileType = false;
                this.ngxUiLoaderService.stop();
                this.submitBtnEnable();
                return false;
            }


            if (event.target.files[0].size > max_size) {
                this.fileuploadError = 'Maximum size allowed is 10MB';
                this.toastrService.info('', this.fileuploadError);
                this.ohContestvideoFile.nativeElement.value = "";
                this.isValidFileSize = false;
                this.ngxUiLoaderService.stop();
                this.submitBtnEnable();
                return false;
            }

            var ohfile: File = event.target.files[0];
            var ohFileReader: FileReader = new FileReader();

            ohFileReader.onloadend = (e) => {
                this.base64videoFile = ohFileReader.result;
                this.isValidFileType = true;
                this.isValidFileSize = true;
                this.isFileEncoded = true;
                this.ngxUiLoaderService.stop();
                this.submitBtnEnable();
            }
            ohFileReader.readAsDataURL(ohfile);
            this.ngxUiLoaderService.stop();


        }
        this.ngxUiLoaderService.stop();

    }
    /***
     * 
     */
    validateField(event: any) {
        if (event.target.name == "og_full_name") {
            this.isNameChanged = true;
        }
        if (event.target.name == "og_email") {
            this.isEmailChanged = true;
        }
        if (event.target.name == "og_phone") {
            this.isPhoneChanged = true;
        }
        if (event.target.name == "og_video_file") {
            this.isFileChanged = true;
        }
        if (event.target.name == "og_tos") {
            this.isTosChanged = true;
            this.checkTOSAccepted(event);
        }
        this.submitBtnEnable();
    }


    resetFormData() {
        this.isNameChanged = false;
        this.isEmailChanged = false;
        this.isPhoneChanged = false;
        this.isFileChanged = false;
        this.isTosChanged = false;
        this.isFileEncoded = false;
        this.isTosValid = false;
    }

    isValidNumber(e: any) {
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
            return false;
        }
        this.validateField(e);
    }

    privacyPolicy() {
        // $("#termsofService").modal('show');
    }

    checkTOSAccepted(event: any) {
        if (event.target.checked) {
            this.isTosValid = true;
        } else {
            this.isTosValid = false;
        }
    }

    submitBtnEnable() {

        if (this.ogVCForm.valid && this.isValidFileType && this.isValidFileSize && this.isFileEncoded && this.isTosValid) {
            this.isSubmitBntEnabled = true;
        } else {
            this.isSubmitBntEnabled = false;
        }

    }

}
